<!--
 * @Author: yangzhiqiang
 * @Date: 2024-02-19 18:13:27
 * @LastEditors: yangzhiqiang
 * @LastEditTime: 2024-02-22 13:39:45
-->
<template>
  <div>
    <div class="box">
      <div class="head-card">
        <div class="box-card" @click="goinfo" :style="$store.getters.getIsPhone?{display:'flex'}:{}">
          <img :style="{width:$store.getters.getIsPhone?'35%':'100%'}" src="../../assets/news/1-1P125222422G6.png" alt="" />
          <div class="box-card_info">
            <div class="info-title">小鸡充值“1·23充值节”，“5G”生活新体验</div>
            <div class="info-msg">小鸡充值APP作为第三方移动充值领域的领导者，率先提出“5G”充值理念，并开创性地打造了专属品牌充值日“1·23充值节”来将这一概念落地。</div>
            <div class="info-time">2018-01-25 22:23:17</div>
          </div>
        </div>
        <!-- 展示内容 -->
        <div class="box-list">
          <el-row :gutter="$store.getters.getIsPhone?30:0">
            <el-col :xs="24" :sm="12" :md="8" v-for="item in ndata" :key="item.id">
              <div class="box-list_item" @click="handleInfo(item)">
                <img :src="require(`../../assets/news/${item.url}`)" alt="" />
                <div class="item-title">{{ item.title }}</div>
                <div class="item-msg">{{ item.msg }}</div>
                <div class="item-time">{{ item.time }}</div>
              </div>
            </el-col>
          </el-row>
        </div>
        <!-- 分页 -->
        <div class="pages">
          <el-pagination @current-change="handleCurrentChange" :page-size="6" layout="total, prev, pager, next" :total="total"> </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { newData } from "../new/new"
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      ndata: [],
      total: 0,
    }
  },
  methods: {
    handleCurrentChange(current) {
      const start = (current - 1) * 6
      const end = current * 6
      this.ndata = newData.slice(start, end)
    },
    goinfo() {
      this.$router.push({ path: "/news/info", query: { id: "180125_1" } })
    },
    handleInfo(item) {
      this.$router.push({ path: "/news/info", query: { id: item.id } })
    },
  },
  mounted() {
    this.ndata = newData.slice(0, 6)
    this.total = newData.length
  },
}
</script>
<style lang="scss" scoped>
.box {
  width: 100%;
}
.head-card {
  max-width: 1200px;
  margin: 0 auto;
  .box-card {
    width: 100%;
    border-radius: 16px;
    padding: 25px;
    background-color: #fff;
    border: 1px solid #d0d0d0;
    box-sizing: border-box;
    img {
      border-radius: 10px;
    }
    .box-card_info {
      flex: 1;
      min-width: 0;
      margin-left: 30px;
      .info-title {
        width: 100%;
        font-size: 42px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .info-msg {
        width: 100%;
        margin-top: 20px;
        font-size: 16px;
        opacity: 0.6;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
      .info-time {
        width: 100%;
        margin-top: 50px;
        font-size: 18px;
        opacity: 0.4;
        font-style: normal;
      }
    }
  }
  .box-card:hover {
    box-shadow: 0 0 10px 0 #00000020;
    cursor: pointer;
  }
  .box-list {
    width: 100%;
    margin-top: 40px;
    .box-list_item {
      width: 100%;
      padding: 25px;
      box-sizing: border-box;
      border: 1px solid #d0d0d0;
      background: #fff;
      border-radius: 10px;
      margin: 15px 0;
      img {
        width: 100%;
      }
      .item-title {
        width: 100%;
        font-size: 22px;
        margin-top: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .item-msg {
        width: 100%;
        height: 76px;
        margin-top: 10px;
        font-size: 16px;
        opacity: 0.6;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
      .item-time {
        width: 100%;
        font-size: 14px;
        margin-top: 10px;
        opacity: 0.4;
        font-style: normal;
      }
    }
    .box-list_item:hover {
      box-shadow: 0 0 10px 0 #00000020;
      cursor: pointer;
    }
    .box-list_item:hover .item-title {
      color: #00b1ed;
    }
    .box-list_item:hover .item-msg {
      text-decoration: underline;
    }
  }
  .pages {
    width: 100%;
    margin-top: 20px;
    text-align: center;
  }
}
</style>
